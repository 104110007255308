<template>
  <apexchart type="area" height="400" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import apexchart from "vue-apexcharts";

export default {
name: "GraficoArea",
props: ["dataInfo", "nombre1", "nombre2", "nombre3"],
components: {
  apexchart,
},
data() {
  return {
    series: [],
    chartOptions: {
      chart: {
        type: "area",
        height: 400,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
            type: 'category',
            categories: [""]
      },
      yaxis: {
        title: {
          text: "Cantidad",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {},
      },
    },
  };
},
mounted() {
  this.cargarInformacionGrafico();
},
methods: {
  cargarInformacionGrafico() {
    //console.log(this.nombre1);
    //console.log(this.nombre2);
    this.chartOptions.xaxis.categories.length = 0;
    //console.log(this.dataInfo);
    const newData1 = [];
    const newData2 = [];
    const newData3 = [];

    this.dataInfo.forEach((val) => {
      this.chartOptions.xaxis.categories.push(val.nombre);
      newData1.push(val.cantidad);
      newData2.push(val.cantidadFallidas);
      newData3.push(val.total);
    });
    if (this.nombre2 === "Null") {
      this.series = [
        {
          name: this.nombre1,
          data: newData1,
        },
        {
          name: this.nombre3,
          data: newData3,
        },
      ];
    }
    else {
      this.series = [
        {
          name: this.nombre1,
          data: newData1,
        },
        {
          name: this.nombre2,
          data: newData2,
        },
        {
          name: this.nombre3,
          data: newData3,
        },
      ];
    }
  },
},
};
</script>