<template>
    <main-layout itemMenuActive="43" tituloMenu="Cantidad Transacciones Acumuladas">
      <div class="container">
        <br />
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <div v-if="datosUsuario.length > 0">
                    <b-field grouped>
                      <b-field>
                        <p class="has-text-left subtitle">
                          Onboarding Digital
                        </p>
                      </b-field>
                    </b-field>
                    <grafico-area
                      :dataInfo="datosUsuario"
                      :nombre1="'Exitosas'"
                      :nombre2="'No Exitosas'"
                      :nombre3="'Total Tx'"
                    ></grafico-area>
                  </div>
                  <div v-else>
                    <b-message type="is-info">
                      Sin datos para procesar.
                    </b-message>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="columns is-mobile is-multiline is-centered" v-if="datosAuten.length > 0">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <b-field grouped>
                    <b-field>
                      <p class="has-text-left subtitle">
                        Reconocimiento Facial
                      </p>
                    </b-field>
                  </b-field>
                  <grafico-area :dataInfo="datosAuten" :nombre1="'Exitosas'" :nombre2="'No Exitosas'"
                    :nombre3="'Total Tx'"></grafico-area>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
    </main-layout>
  </template>
  
  <script>
  import MainLayout from "@/components/Layout/MainLayout";
  import GraficoArea from "@/components/Graficos/GraficoArea";
  import { mapActions, mapState } from "vuex";
  
  export default {
    name: "TransaccionesOnboardingAcumuladoFinan",
    components: { MainLayout, GraficoArea },
    data() {
      return {
        isLoading: false,
        cantidad: null,
        datosUsuario: [],
        datosLicencias: [],
        datosAuten: [],
        datosReg: [],
        datosFirmas: [],
      };
    },
    mounted() {
      this.obtenerDatos();
    },
    methods: {
      obtenerDatos() {
        if (this._PERFIL_MODULO_.onboarding) {
          this.isLoading = true;
  
          const options = {
            method: "get",
            uri: "finan/acumulado",
          };
  
          this.datosUsuario = [];
          this.datosLicencias= [];
          this.datosAuten= [];
          this.datosReg= [];
          this.datosFirmas= [];
  
          this._axios(options)
            .then((response) => {
              this.datosUsuario = response.data.sop;
              this.datosAuten = response.data.auten;
            })
            .catch((error) => {
              this.isLoadingTable = false;
              console.log(error);
            })
            .finally(() => this.cancelarLoading());
        } else {
          this.$router.push("login");
        }
      },
      exportExcel: function () {
        let routeData = `${this._HOST_SERVE}/export/exportExcelAcomuladoPorMesOnboarding`;
        window.open(routeData, "_blank");
      },
      exportPDF: function () {
        let routeData = `${this._HOST_SERVE}/export/exportPDFAcomuladoPorMesOnboarding`;
        window.open(routeData, "_blank");
      },
      alertCustomError(msj, titulo = "Error", tipo = "is-danger") {
        this.$buefy.dialog.alert({
          title: titulo,
          message: msj,
          type: tipo,
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
      cancelarLoading() {
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      },
      ...mapActions(["_axios"]),
    },
    computed: {
      ...mapState(["_PERFIL_MODULO_", "_HOST_SERVE"]),
    },
  };
  </script>